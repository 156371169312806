import {noop} from 'lodash';

import {
  chilipiper,
  initDataDog,
  initLogRocket,
  initSegment,
  initSentry,
  isTestSession,
} from '@renofi/analytics';

export const initAnalytics = () => {
  if (isTestSession()) {
    return;
  }

  initSegment();
  initLogRocket();
  initSentry();
  initDataDog();
  initDataDog({envs: ['staging']});
};

export const launchChilipiper = ({
  onClose = noop,
  onSuccess = noop,
  router = 'partnerships-contractors',
  user = {},
} = {}) => {
  const {email, firstName, lastName, phoneNumber: phone} = user || {};

  chilipiper.initSchedule({
    analyticsPrefix: 'Contractors',
    lead: {email, firstName, lastName, phone},
    onClose,
    onSuccess,
    router,
  });
};
