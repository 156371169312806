import React, {useState, useContext} from 'react';

import {useApolloClient} from '@apollo/client';
import {Box, Flex, Text} from 'rebass';
import {useHistory} from 'react-router-dom';

import {useCompleteContractorRegistration} from '@renofi/api';
import {useChannel} from '@renofi/utils/src/pusher';
import {
  Button,
  Label,
  PhoneField,
  TextField,
  Alert,
  SelectField,
  useNotifications,
} from '@renofi/components';
import {useLocalStorage, useUTMTags} from '@renofi/utils';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';
import {initAuthTokenRefresh} from '@renofi/modules';
import {sendEvent} from '@renofi/analytics';

import AppContext from '../context';
import {getContractorCacheKey} from '../utils';

import {RoundedBox} from './styled';

const CONTACT_ROLES = [
  {
    label: 'Owner',
    value: 'owner',
  },
  {
    label: 'Project Manager',
    value: 'project_manager',
  },
  {
    label: 'Sales and Marketing',
    value: 'sales_and_marketing',
  },
  {
    label: 'Designer',
    value: 'designer',
  },
  {
    label: 'Finance and Accounting',
    value: 'finance_and_accounting',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const BusinessContact = () => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const addNotification = useNotifications();
  const {user, refetch: fetchCurrentUser} = useContext(AppContext);
  const [contractor] = useLocalStorage(getContractorCacheKey(user?.id), {});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const {completeContractorRegistration} = useCompleteContractorRegistration();
  const {utmTags} = useUTMTags(UTM_COOKIE_KEYS.renofi);

  const onChangeValue = (key) => (value) => {
    if (key === 'companyRole' && value !== 'other') {
      setFormData((s) => ({
        ...s,
        companyRole: 'other',
        companyRoleDetails: '',
      }));
    }
    setFormData((s) => ({...s, [key]: value}));
  };

  useChannel({
    channelName: `contractor_account_category_assigned--user=${user?.id}`,
    onUpdate: async () => {
      // As the User changed on registration, we NEED to refresh the JWT
      const newTimeoutId = await initAuthTokenRefresh(apolloClient, true);
      if (newTimeoutId) {
        await fetchCurrentUser();
      }
      setLoading(false);
      history.push('/welcome');
    },
  });

  const disabled =
    loading ||
    !(
      formData?.firstName &&
      formData?.lastName &&
      formData?.companyRole &&
      formData?.phoneNumber
    );

  const onSubmit = async () => {
    setLoading(true);
    const contact = {
      email: user.email,
      ...formData,
    };

    try {
      await completeContractorRegistration({
        variables: {
          contractor,
          contact,
        },
      });

      sendEvent('Contractors/Contractor-Registration-Completed', {
        ...contact,
        ...contractor,
        utmSource: utmTags.source,
      });
    } catch (e) {
      setLoading(false);
      sendEvent('Contractors/User-Registation-Error', {
        message: e.message,
      });
      addNotification({
        type: 'error',
        message: 'There was a problem completing the registration.',
      });
    }
  };

  return (
    <Box maxWidth={678} alignSelf={'center'}>
      <Text
        mt={2}
        fontSize={[32, 36]}
        lineHeight={['32px', '46px']}
        textAlign={['center', 'left']}>
        Finally, who is the best contact for the business?
      </Text>

      <Box
        fontSize={16}
        color={'#00A0FF'}
        textAlign={['center', 'left']}
        fontStyle={'italic'}
        mt={4}>
        You can add more team members later.
      </Box>
      <Box mt={[2, 4]}>
        <Alert info css={{fontSize: 14, lineHeight: '20px', textAlign: 'left'}}>
          The information entered will ONLY be used by RenoFi to communicate
          important information with you and connect you with homeowners who are
          looking for contractors.
        </Alert>
      </Box>
      <RoundedBox mt={4}>
        <Flex css={{gap: 32}} flexDirection={['column', 'row']}>
          <Box flex={[1, 0.5]}>
            <Label small htmlFor="firstName">
              First name
            </Label>
            <TextField
              id="firstName"
              name="firstName"
              onChange={onChangeValue('firstName')}
              placeholder="First name"
              value={formData?.firstName}
            />
          </Box>

          <Box flex={[1, 0.5]}>
            <Label small htmlFor="lastName">
              Last name
            </Label>
            <TextField
              id="lastName"
              name="lastName"
              onChange={onChangeValue('lastName')}
              placeholder="Last name"
              value={formData?.lastName}
            />
          </Box>
        </Flex>
        <Flex my={4} css={{gap: 32}} flexDirection={['column', 'row']}>
          <Box flex={[1, 0.5]}>
            <Label small htmlFor="companyRole">
              Your role at the company
            </Label>
            <SelectField
              id="companyRole"
              name="companyRole"
              onChange={onChangeValue('companyRole')}
              value={formData?.companyRole}>
              <>
                {!formData?.companyRole && <option />}
                {CONTACT_ROLES.map(({label, value}) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </>
            </SelectField>
            {formData?.companyRole === 'other' && (
              <>
                <Label small mt={32} htmlFor="companyRoleDetails">
                  If Other, please specify.
                </Label>
                <TextField
                  id="companyRoleDetails"
                  name="companyRoleDetails"
                  onChange={onChangeValue('companyRoleDetails')}
                  placeholder="Company role"
                  value={formData?.companyRoleDetails}
                />
              </>
            )}
          </Box>
          <Box flex={[1, 0.5]}>
            <Label small htmlFor="phoneNumber">
              Phone number
            </Label>
            <PhoneField
              id="phoneNumber"
              name="phoneNumber"
              onChange={onChangeValue('phoneNumber')}
              placeholder="Phone number"
              value={formData?.phoneNumber}
            />
          </Box>
        </Flex>
        <Flex flexDirection={['column', 'row']} css={{gap: 8}}>
          <Button type="button" onClick={history.goBack} secondary>
            BACK
          </Button>
          <Button
            primary
            role="submit_business_contact"
            disabled={disabled || loading}
            loading={loading}
            onClick={onSubmit}>
            FINISH
          </Button>
        </Flex>
      </RoundedBox>
    </Box>
  );
};

export default BusinessContact;
